import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import featureImage from "../../static/images/8419-images-acr1255u-j1.png";
import thumbnailEvent from "../../static/images/feature-event.png"
import thumbnailBoard from "../../static/images/feature-board.png"
import thumbnailNews from "../../static/images/feature-news.png"
import thumbnailTeams from "../../static/images/feature-team.png"
import thumbnailStaff from "../../static/images/feature-user.png"

const IndexPage = () => (
    <Layout>
        <SEO title="Contactless.com.au"/>

        <div className={"page-header home"}>
            <h1>Contactless.com.au</h1>
            <p>Contactless technologies in Australia<br/>Visit <a href="https://www.posnow.com.au">POS Now<a/></a>.</p>
            <img alt={"Dashboard"} src={featureImage}/>
        </div>

        <div className={"container"}>

        </div>

    </Layout>
)

export default IndexPage
